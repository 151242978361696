import styled from "styled-components"

import { EmptyState } from "src/components/EmptyState"
import { ParadiseDetailsLayout } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseOrderDetailsMeta } from "src/components/Paradise/ParadiseOrders/ParadiseOrderDetails/ParadiseOrderDetailsMeta"
import { ParadiseOrderFulfillment } from "src/components/Paradise/ParadiseOrders/ParadiseOrderDetails/ParadiseOrderFulfillment"
import { ParadiseOrderOverview } from "src/components/Paradise/ParadiseOrders/ParadiseOrderDetails/ParadiseOrderOverview"
import { ParadiseOrderAddressSection } from "src/components/Paradise/ParadiseOrders/ParadiseOrderDetails/ParadiseOrderShippingAddress"
import { ParadiseOrderTimeline } from "src/components/Paradise/ParadiseOrders/ParadiseOrderDetails/ParadiseOrderTimeline"
import { LoadingWrapper } from "src/components/Paradise/sharedStyles"
import { useAddRecentlyViewedPage } from "src/components/RecentlyViewed/useAddRecentlyViewedPage"
import { useFetchParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderQueries"
import { useFetchParadiseUser } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import Svg404 from "src/ui/icons/404.svg"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"

export function ParadiseOrderDetails({ orderNumber }: { orderNumber: string }) {
  const { t } = useTranslate()

  const fetchOrder = useFetchParadiseOrder({
    orderNumber,
  })

  const fetchUser = useFetchParadiseUser({
    userId: fetchOrder.data?.user_id ?? "",
    options: {
      enabled: !!fetchOrder.data?.user_id,
    },
  })

  useAddRecentlyViewedPage({
    title: fetchOrder.data?.id,
    enitytId: fetchOrder.data?.id,
    type: "Order",
    enabled: fetchOrder.isSuccess,
  })

  if (fetchOrder.isError) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }

  if (fetchOrder.isLoading) {
    return (
      <LoadingWrapper>
        <MSkeleton height={40} width="30ch" />
        <MSkeleton height={20} width="40ch" />
      </LoadingWrapper>
    )
  }

  const order = fetchOrder.data

  return (
    <ParadiseDetailsLayout
      meta={<ParadiseOrderDetailsMeta order={order} />}
      left={<ParadiseOrderOverview order={order} user={fetchUser.data} />}
      right={
        <>
          <ParadiseOrderFulfillment order={order} />
          <AddressWrapper>
            <ParadiseOrderAddressSection
              title="Shipping Address"
              shippingAddress={order.shipping_address}
            />
            <ParadiseOrderAddressSection
              title="Billing Address"
              shippingAddress={order.billing_address}
            />
          </AddressWrapper>
          <ParadiseOrderTimeline />
        </>
      }
      breadcrumbs={[
        {
          label: "Orders",
          to: Routes.ParadiseOrders.location(),
        },
        {
          label: order.order_number,
          to: Routes.ParadiseOrder.location(order.order_number),
        },
      ]}
      breadcrumbCopyValue={order.order_number}
    />
  )
}

const AddressWrapper = styled.div`
  display: flex;
  gap: ${spacing.L};
`
