import { TParadiseOrdersQueryParams } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { arrayIncludes } from "src/utils/genericUtil"

export const validParadiseOrdersSortIds: NonNullable<
  TParadiseOrdersQueryParams["sort_by"]
>[] = ["created_at", "status"]

export function parseParadiseOrdersSortField(
  sortValue: string | undefined
): sortValue is TParadiseOrdersQueryParams["sort_by"] {
  if (!sortValue || !arrayIncludes(validParadiseOrdersSortIds, sortValue)) {
    return false
  }

  return true
}
