import { createBasicLocation } from "src/router/routeTypes"

const REPORTS_PATH = "/reports"

const Reports = createBasicLocation(REPORTS_PATH)
const MoldInsights = createBasicLocation(`${REPORTS_PATH}/mold-insights`)

export const ReportRoutes = {
  Reports,
  MoldInsights,
}
