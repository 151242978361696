import styled from "styled-components"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { countryCodeToCountry } from "src/data/countries/countryUtil"
import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ParadiseOrderAddressSection({
  title,
  shippingAddress,
}: {
  title: string
  shippingAddress:
    | TParadiseOrder["shipping_address"]
    | TParadiseOrder["billing_address"]
}) {
  return (
    <div>
      <ParadiseDetailsSection title={title} />

      <Grid>
        <MText variant="bodyS" color="secondary">
          Name:
        </MText>

        <MText variant="bodyS">
          {shippingAddress.first_name} {shippingAddress.last_name}
        </MText>

        <MText variant="bodyS" color="secondary">
          Phone:
        </MText>
        <MText variant="bodyS">{shippingAddress.phone}</MText>

        {shippingAddress.company && (
          <>
            <MText variant="bodyS" color="secondary">
              Company:
            </MText>
            <MText variant="bodyS">{shippingAddress.company}</MText>
          </>
        )}

        <MText variant="bodyS" color="secondary">
          Line 1:
        </MText>
        <MText variant="bodyS">{shippingAddress.line1}</MText>

        {shippingAddress.line2 && (
          <>
            <MText variant="bodyS" color="secondary">
              Line 2:
            </MText>
            <MText variant="bodyS">{shippingAddress.line2}</MText>
          </>
        )}

        <MText variant="bodyS" color="secondary">
          City:
        </MText>
        <MText variant="bodyS">{shippingAddress.city}</MText>

        <MText variant="bodyS" color="secondary">
          Zip:
        </MText>
        <MText variant="bodyS">{shippingAddress.zip}</MText>

        <MText variant="bodyS" color="secondary">
          State:
        </MText>
        <MText variant="bodyS">{shippingAddress.state_code}</MText>

        <MText variant="bodyS" color="secondary">
          Country:
        </MText>
        <MText variant="bodyS">
          {countryCodeToCountry(shippingAddress.country)}
        </MText>
      </Grid>
    </div>
  )
}

const Grid = styled.div`
  margin-top: ${spacing.XS2};
  display: grid;
  column-gap: ${spacing.L};
  grid-template-columns: max-content 1fr;
`
