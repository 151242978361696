import { forwardRef } from "react"
import styled from "styled-components"

import { ButtonStyleReset } from "src/ui/Button/MButton"
import { colorScale, mColors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { SVGProps } from "src/utils/tsUtil"

type MChipProps = {
  label: React.ReactNode
  Icon: React.FC<SVGProps>
  onClick: () => void
  active?: boolean
  borderType?: "solid" | "dashed"
  size?: "small" | "medium"
}

function _MChip(
  {
    label,
    Icon,
    onClick,
    active,
    size = "medium",
    borderType = "solid",
  }: MChipProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <ChipWrapper
      onClick={onClick}
      $borderType={borderType}
      $size={size}
      $active={!!active}
      ref={ref}
    >
      <Icon width={9} />
      <MText variant="bodyS">{label}</MText>
    </ChipWrapper>
  )
}

export const MChip = forwardRef(_MChip)

const ChipWrapper = styled.button<{
  $borderType: "solid" | "dashed"
  $size: "small" | "medium"
  $active: boolean
}>`
  ${ButtonStyleReset}

  display:inline-flex;
  align-items: center;
  gap: ${spacing.XS};
  padding-inline: ${spacing.XS2};
  padding-block: ${({ $size }) => ($size === "small" ? 0 : spacing.XS3)};
  background-color: ${mColors.neutralLight};
  border-radius: 50px;
  border-width: 1px;
  border-style: ${({ $borderType, $active }) =>
    $borderType === "dashed" && !$active ? "dashed" : "solid"};
  border-color: ${({ $active }) =>
    $active ? mColors.systemInfo : mColors.divider};
  white-space: nowrap;

  transition: background-color 0.2s;

  &:hover,
  &:focus-visible {
    background-color: ${mColors.neutral};
    border-style: solid;
  }

  &:focus-visible {
    border-color: ${colorScale.koti[600]};
  }
`
