import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { MBadge, MBadgeProps } from "src/ui/MBadge/MBadge"
import { capitalizeFirstChar } from "src/utils/l10n"

export function OrderStatusBadge({
  status,
  size = "x-small",
}: {
  status: TParadiseOrder["status"]
  size?: MBadgeProps["size"]
}) {
  function getStatusColor(
    status: TParadiseOrder["status"]
  ): MBadgeProps["color"] {
    switch (status) {
      case "shipped":
      case "delivered":
        return "good"
      case "cancelled":
        return "error"
      default:
        return "warning"
    }
  }

  return (
    <MBadge size={size} color={getStatusColor(status)}>
      {capitalizeFirstChar(status.replace("_", " "))}
    </MBadge>
  )
}
