import { ParadiseAppliedFilters } from "src/components/Paradise/ParadiseAppliedFilters"
import { TParadiseOrdersSearchFilter } from "src/components/Paradise/ParadiseOrders/useParadiseOrdersFilter"
import { FilterWrapper } from "src/components/Paradise/sharedStyles"
import {
  TSetSearchParamsProp,
  TSortedSearchParams,
} from "src/router/useSearchParams"
import { MButton } from "src/ui/Button/MButton"

const ordersFilterLabel: Record<keyof TParadiseOrdersSearchFilter, string> = {
  orders_offset: "Offset",
  orders_order_number: "Order number",
  orders_user_id: "User ID",
} as const

export function ParadiseOrdersFilters({
  setFilter,
  sortedFilters,
}: {
  setFilter: TSetSearchParamsProp<TParadiseOrdersSearchFilter>
  sortedFilters: TSortedSearchParams<keyof TParadiseOrdersSearchFilter>
}) {
  const appliedFilters = sortedFilters.map((f) => {
    const label = ordersFilterLabel[f.key]

    return {
      label: label ?? "",
      value: String(f.value),
      onRemove: () => setFilter(f.key, null),
    }
  })
  return (
    <FilterWrapper>
      <ParadiseAppliedFilters filters={appliedFilters} />

      {appliedFilters.length > 0 && (
        <MButton
          onClick={() => {
            appliedFilters.forEach((filter) => filter.onRemove())
          }}
          variant="minimal"
          size="small"
        >
          Clear filters
        </MButton>
      )}
    </FilterWrapper>
  )
}
