import styled from "styled-components"

import { SettingToggle } from "src/components/Settings/Setting/SettingToggle"
import { EventButton } from "src/components/VirtualDeviceControl/EventButton"
import { SliderSetting } from "src/components/VirtualDeviceControl/SettingsSlider"
import { VirtualDeviceBatteryIcon } from "src/components/VirtualDeviceControl/VirtualDeviceBatteryIcon"
import {
  HardwareVersionBoundary,
  TVirtualDevice,
} from "src/data/devices/types/deviceTypes"
import {
  IVirtualEnvironmentRequest,
  usePostVirtualDeviceEvent,
  usePostVirtualEnvironment,
} from "src/data/homes/queries/virtualDeviceQueries"
import { DEFAULT_HOME_ID, THome } from "src/data/homes/types/homeTypes"
import { useFetchHome } from "src/data/organizations/queries/homeQueries"
import SoundIcon from "src/ui/icons/eventIcons/event-noise.svg"
import HumidityIcon from "src/ui/icons/humidity.svg"
import MoldRiskIcon from "src/ui/icons/mold.svg"
import TempIcon from "src/ui/icons/temperature.svg"
import WifiGoodIcon from "src/ui/icons/wifi-good.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function VirtualMDeviceControls({
  orgId,
  home,
  simulatedDevice,
}: {
  orgId: string
  home: THome
  simulatedDevice: TVirtualDevice
}) {
  const postVirtualDeviceEvent = usePostVirtualDeviceEvent()
  const postVirtualEnvironment = usePostVirtualEnvironment()

  const homeId = simulatedDevice.home || DEFAULT_HOME_ID

  const fetchHomes = useFetchHome({ orgId, homeId })

  const isICMEnabled =
    fetchHomes.data?.indoor_climate_monitoring?.state === "enabled"

  if (!simulatedDevice.virtual_environment) {
    return null
  }

  /**
   * Sends an event to the virtual device
   * @returns true or false depending on if the request was successful
   */
  async function sendDeviceEvent(
    eventType: IVirtualEnvironmentRequest["event_type"]
  ): Promise<boolean> {
    try {
      await postVirtualDeviceEvent.mutateAsync({
        orgId,
        deviceId: simulatedDevice.device_id,
        homeId: homeId,
        eventType: eventType,
      })
      return true
    } catch (e) {
      return false
    }
  }

  /**
   * Changes the body of the virtual environment
   * @param body The updated parameters of the body
   * @returns true or false depending on if the request was successful
   */
  async function changeBody(
    body: IVirtualEnvironmentRequest
  ): Promise<boolean> {
    try {
      await postVirtualEnvironment.mutateAsync({
        orgId,
        deviceId: simulatedDevice.device_id,
        homeId: simulatedDevice.home ?? DEFAULT_HOME_ID,
        body: body,
      })
      return true
    } catch (e) {
      return false
    }
  }

  const threshold =
    simulatedDevice.configuration?.reactions.find(
      (reaction) => reaction.type === "sound_level_high"
    )?.value ?? 60 // Default to 60 if no threshold is set

  const smokingDetectionAvailable =
    simulatedDevice.hardware_version >=
    HardwareVersionBoundary.P3_BOUNDARY_START

  const isCalibrationDone =
    new Date(
      home?.smoking_detection?.calibration_done_at ??
        new Date(new Date().getTime() + 100 * 1000)
    ) < new Date()

  return (
    <CardWrapper>
      <Column>
        <SliderSetting
          label="dB"
          orgId={orgId}
          simulatedDevice={simulatedDevice}
          Icon={SoundIcon}
          step={2}
          min={30}
          max={100}
          attribute={"sound_level"}
          marks={[30, threshold, 90].map((value) => ({
            value,
            label: `${value} dBa`,
          }))}
          enabled={simulatedDevice.offline === false}
        />

        <SliderSetting
          label="V"
          orgId={orgId}
          simulatedDevice={simulatedDevice}
          Icon={() => (
            <VirtualDeviceBatteryIcon
              voltage={simulatedDevice.battery?.voltage ?? 0}
              device={simulatedDevice}
            />
          )}
          step={0.01}
          min={3.3}
          max={4.2}
          attribute={"battery_voltage"}
          enabled={
            simulatedDevice.offline === false ||
            simulatedDevice.battery?.voltage <= 3.3
          } // 3.3 is the lowest voltage and means virtual device is offline
          marks={[
            {
              value: 3.3,
              label: "Empty",
            },
            {
              value: 3.55,
              label: "Low",
            },
            {
              value: 3.8,
              label: "Normal",
            },
            {
              value: 4.15,
              label: "Full",
            },
          ]}
        />

        <SliderSetting
          label="°C"
          orgId={orgId}
          simulatedDevice={simulatedDevice}
          Icon={TempIcon}
          step={1}
          min={-20}
          max={50}
          attribute={"temperature"}
          enabled={simulatedDevice.offline === false && isICMEnabled}
          marks={[-10, 20, 45].map((value) => ({ value, label: `${value}°C` }))}
        />

        <SliderSetting
          label="%"
          orgId={orgId}
          simulatedDevice={simulatedDevice}
          Icon={HumidityIcon}
          step={1}
          min={0}
          max={100}
          attribute={"humidity"}
          enabled={simulatedDevice.offline === false && isICMEnabled}
          marks={[0, 50, 100].map((value) => ({ value, label: `${value}%` }))}
        />

        <SliderSetting
          label="level"
          orgId={orgId}
          simulatedDevice={simulatedDevice}
          Icon={MoldRiskIcon}
          step={1}
          min={1}
          max={3}
          attribute={"mould_risk_level"}
          enabled={simulatedDevice.offline === false}
          marks={[
            {
              value: 1,
              label: "Low",
            },
            {
              value: 2,
              label: "Elevated",
            },
            {
              value: 3,
              label: "High",
            },
          ]}
        />

        <EventButton
          eventType={"generate_mould_risk_data"}
          orgId={orgId}
          deviceId={simulatedDevice.device_id}
          homeId={homeId}
          disabled={simulatedDevice.offline === true}
          label="1 year random mould risk data"
        />

        <SliderSetting
          label="db"
          orgId={orgId}
          simulatedDevice={simulatedDevice}
          Icon={WifiGoodIcon}
          step={1}
          min={-100}
          max={0}
          attribute={"wifi_rssi"}
          enabled={
            simulatedDevice.offline === false ||
            simulatedDevice.virtual_environment.wifi_rssi <= -100
          } // -100 is the lowest rssi and means virtual device is offline
          marks={[
            { value: -100, label: "Offline" },
            ...[-50, 0].map((value) => ({
              value,
              label: `${value}db`,
            })),
          ]}
        />
      </Column>
      <Column>
        <SettingsToggleWrapper>
          <EventButton
            eventType="button_pressed"
            orgId={orgId}
            deviceId={simulatedDevice.device_id}
            homeId={homeId}
            disabled={simulatedDevice.offline}
            label="Button press"
          />

          <EventButton
            eventType="smoking_detected"
            orgId={orgId}
            deviceId={simulatedDevice.device_id}
            homeId={homeId}
            disabled={
              !smokingDetectionAvailable ||
              !isCalibrationDone ||
              simulatedDevice.offline
            }
            label="Smoking detected"
          />

          {!isCalibrationDone && (
            <InfoMessage variant="bodyS" color="secondary">
              Cannot trigger smoking event until calibration is done
            </InfoMessage>
          )}

          <SettingToggle
            title="Smoking calibration done"
            titleProps={{ variant: "body" }}
            value={smokingDetectionAvailable && isCalibrationDone}
            disabled={
              !smokingDetectionAvailable ||
              simulatedDevice.offline ||
              home.smoking_detection?.state === "disabled"
            }
            onSave={async (checked) => {
              const result = await changeBody({
                smoking_calibration_done: checked
                  ? new Date(
                      new Date().getTime() - 1000 * 3600 * 24
                    ).toISOString()
                  : new Date(
                      new Date().getTime() + 1000 * 3600 * 24
                    ).toISOString(),
              })
              return { isSuccess: result }
            }}
          />
          {!smokingDetectionAvailable && (
            <InfoMessage variant="bodyS" color="secondary">
              Only M3 & A1 devices can trigger smoking detection
            </InfoMessage>
          )}

          <SettingToggle
            title="Power switch"
            titleProps={{ variant: "body" }}
            value={simulatedDevice.virtual_environment?.power}
            disabled={
              simulatedDevice.offline &&
              simulatedDevice.virtual_environment?.power !== false
            }
            onSave={async (checked) => {
              const result = await sendDeviceEvent(
                checked ? "device_power_on" : "device_power_off"
              )
              return { isSuccess: result }
            }}
          />

          <SettingToggle
            title="Almost freezing"
            titleProps={{ variant: "body" }}
            value={!!simulatedDevice.virtual_environment?.is_almost_freezing}
            disabled={
              simulatedDevice.offline ||
              !!simulatedDevice.placed_outdoors ||
              !isICMEnabled
            }
            onSave={async (checked) => {
              const result = await sendDeviceEvent(
                checked ? "almost_freezing" : "almost_freezing_cleared"
              )
              return { isSuccess: result }
            }}
          />

          <SettingToggle
            title="Mounted"
            titleProps={{ variant: "body" }}
            value={simulatedDevice.mount_status === "mounted"}
            disabled={simulatedDevice.offline}
            onSave={async (checked) => {
              const result = await sendDeviceEvent(
                checked ? "tamper_mounted" : "tamper_removed"
              )
              return { isSuccess: result }
            }}
          />

          <SettingToggle
            title="Outdoor"
            titleProps={{ variant: "body" }}
            value={!!simulatedDevice.placed_outdoors}
            disabled={simulatedDevice.offline}
            onSave={async (checked) => {
              const result = await changeBody({
                outdoor: checked ? true : false,
              })
              return { isSuccess: result }
            }}
          />
        </SettingsToggleWrapper>
      </Column>
    </CardWrapper>
  )
}

const InfoMessage = styled(MText)`
  max-width: 240px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: space-between;
  gap: ${spacing.XS};
`

const SettingsToggleWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: ${spacing.XS};
`

const CardWrapper = styled.div`
  display: flex;
  gap: ${spacing.XL};
`
