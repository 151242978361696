import {
  TUpgradeBlockerContext,
  UpgradeBlockerButton,
} from "src/components/FeatureBlockers/UpgradeBlockerButton"
import { UpgradeIconPopover } from "src/components/FeatureBlockers/UpgradeIconPopover"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { TPlan } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"

type UpgradeIconButtonProps = {
  className?: string
  requiredPlan?: TPlan
  context: TUpgradeBlockerContext
  description?: React.ReactNode
  hidden?: boolean
}
export function UpgradeIconButton({
  className,
  context,
  requiredPlan,
  description,
  hidden,
}: UpgradeIconButtonProps) {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const isOrgOwner = getAccessLogic({ role: org.user_role }).hasOwnerAccess
  const error = !isOrgOwner
    ? t(langKeys.subscription_upgrade_blocker_title_non_owner)
    : null

  return (
    <UpgradeIconPopover
      text={t(langKeys.subscription_upgrade_blocker_title)}
      description={description}
      className={className}
      hidden={hidden}
      ctaButton={
        <UpgradeBlockerButton
          size="small"
          context={context}
          disabled={!isOrgOwner}
          plan={requiredPlan}
        />
      }
      errorMsg={error}
    />
  )
}
