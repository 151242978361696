import styled from "styled-components"

import { cardBoxShadow } from "src/constants/shadows"
import { ButtonStyleReset } from "src/ui/Button/MButton"
import { mColors } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

export const ColumnHeaderWrapper = styled.div<{
  $justify?: "compact" | "grow"
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ $justify }) =>
    $justify === "compact" ? "flex-start" : "space-between"};
  gap: ${spacing.S};

  // Remove this when MPopover is refactored to better support triggers
  // without being context aware
  & > div:first-child {
    flex: 1;
  }
`

export const ColumnHeaderLabelButton = styled.button`
  ${ButtonStyleReset}
  border-radius: 0;
  background-color: transparent;
  border: none;
  padding: 0;
  font-weight: unset;
  color: unset;

  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${spacing.XS};
  min-height: 24px;
  user-select: none;

  &::before {
    --offset: 4px;

    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: calc(var(--offset) * -1);
    right: calc(var(--offset) * -1);
    height: 100%;
    border-radius: 4px;
    background-color: ${mColors.neutralLight};
    transition: background-color 0.2s;
    z-index: -1;
  }

  &:hover::before,
  &:focus-visible::before {
    background-color: ${mColors.neutral};
  }

  &:focus-visible::before {
    outline: 1px solid ${mColors.neutralDark3};
  }
`

export const ColumnHeaderLabelWrapper = styled.div`
  min-height: 24px;
`

export const IconWrapper = styled.div<{ $up?: boolean }>`
  rotate: ${({ $up }) => ($up ? "180deg" : "0deg")};
`
export const SortingIconsButton = styled.button`
  ${ButtonStyleReset}

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 0;

  background-color: ${mColors.neutralLight};
  border: none;
  padding: 0;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  transition: background-color 0.2s;
  color: ${mColors.textSecondary};

  &:hover,
  &:focus-visible {
    background-color: ${mColors.neutral};
  }

  &:focus-visible {
    outline: 1px solid ${mColors.neutralDark3};
  }
`

// ts-prune-ignore-next
export const ColumnPopoverWrapper = styled.div`
  background-color: ${mColors.neutralLight};
  padding: ${spacing.XS};
  border: 1px solid ${mColors.neutral};
  border-radius: 0.5rem;
  ${cardBoxShadow}
`
