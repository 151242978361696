import { useParadiseOrdersFilter } from "src/components/Paradise/ParadiseOrders/useParadiseOrdersFilter"
import { ParadiseOrdersTable } from "src/components/Paradise/ParadiseOrdersTable"
import {
  ParadiseLayout,
  ParadiseTitle,
} from "src/components/Paradise/sharedStyles"
import { parseParadiseOrdersSortField } from "src/data/paradise/paradiseOrders/logic/paradiseOrderLogic"
import { useFetchParadiseOrders } from "src/data/paradise/paradiseOrders/paradiseOrderQueries"
import { useFetchParadiseUsers } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { useSortParam } from "src/ui/GridTable/useTableColumns/useSortParam"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { MBadge } from "src/ui/MBadge/MBadge"
import { spacing } from "src/ui/spacing"
import { unique } from "src/utils/genericUtil"

export function ParadiseOrders() {
  const { filter, setFilter, offset, setOffset, sortedFilters } =
    useParadiseOrdersFilter()

  const { sortValue, setSortValue } = useSortParam()

  const limit = 50

  const fetchParadiseOrders = useFetchParadiseOrders({
    filter: {
      limit,
      offset,
      user_id: filter.orders_user_id || undefined,
      order_number: filter.orders_order_number || undefined,
      sort_by: parseParadiseOrdersSortField(sortValue?.id)
        ? sortValue?.id
        : undefined,
      sort_order: sortValue?.order || undefined,
    },
    options: {
      keepPreviousData: true,
    },
  })

  const fetchParadiseUsers = useFetchParadiseUsers({
    filter: {
      ids: unique(
        fetchParadiseOrders.data?.orders.map((order) => order.user_id)
      ).join(","),
    },
    options: {
      enabled: !!fetchParadiseOrders.data?.paging.total_count,
      keepPreviousData: true,
    },
  })

  return (
    <ParadiseLayout>
      <Titlebar
        bottomMargin={spacing.S}
        title={
          <ParadiseTitle>
            Orders
            <MBadge color="info">
              Total:{" "}
              {fetchParadiseOrders.isSuccess &&
                fetchParadiseOrders.data?.paging.total_count}
            </MBadge>
          </ParadiseTitle>
        }
      />

      <ParadiseOrdersTable
        orders={fetchParadiseOrders.data?.orders || []}
        users={fetchParadiseUsers.data?.users}
        filter={filter}
        setFilter={setFilter}
        sortedFilters={sortedFilters}
        sort={sortValue}
        setSort={setSortValue}
        totalCount={fetchParadiseOrders.data?.paging.total_count}
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        isLoadingMore={
          fetchParadiseOrders.isPreviousData ||
          fetchParadiseOrders.isPreviousData
        }
        isLoading={
          fetchParadiseOrders.isLoading || fetchParadiseUsers.isLoading
        }
        error={{
          hasError: fetchParadiseOrders.isError,
          title: fetchParadiseOrders.error?.message,
        }}
      />
    </ParadiseLayout>
  )
}
