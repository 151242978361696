import { format, parseISO } from "date-fns"
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz"
import tzLookup from "tz-lookup"

import { TClockTypeMaybe, TTemperatureUnit, TUser } from "src/data/user/user"
import { Optional } from "src/utils/tsUtil"

/** Convert Celsius to Fahrenheit
 *
 * @param {number} value Temperature in Celsius to convert to Fahrenheit
 * @param {number} decimals Round to this many decimals
 */
export const toFahrenheit = (value: number, decimals = 1) =>
  (value * 1.8 + 32).toFixed(Number(decimals))

export function toCelsius({
  value,
  decimals,
}: {
  value: number
  decimals: number
}) {
  return (((value - 32) * 5) / 9).toFixed(decimals)
}

/** Return formatted temperature unit
 *
 * @param {TTemperatureUnit} unit Temperature unit, i.e., 'C', 'F'
 */
export const formatTemperatureUnitString = (unit: TTemperatureUnit) => {
  switch (unit) {
    case "C":
      return "°C"
    case "F":
      return "°F"
    default:
      throw new Error(`'${unit}' is an invalid unit`)
  }
}

/** Return converted temperature value from Celsius to desired unit
 *
 * @param {number} value Temperature value in celsius
 * @param {TTemperatureUnit} unit Desired temperature unit, i.e., 'C', 'F'
 * @param {number} decimals Round to this many decimals
 */
export const convertTemperatureValue = (
  value: number,
  unit: TTemperatureUnit,
  decimals = 1
) => {
  switch (unit) {
    case "C":
      return value.toFixed(decimals)
    case "F":
      return toFahrenheit(value, decimals)
    default:
      throw new Error(`'${unit}' is an invalid unit`)
  }
}

/** Return localized temperature string
 *
 * @param {number} value Value to format
 * @param {TTemperatureUnit} unit Temperature unit, i.e., 'C', 'F', 'K'
 * @param {number} decimals Round to this many decimals
 */
export const formatTemperatureString = (
  value: number,
  unit: TTemperatureUnit,
  decimals: number = 1
) => {
  return `${convertTemperatureValue(value, unit, decimals)}${formatTemperatureUnitString(unit)}`
}

export const browserLocale = () => navigator?.languages[0] || navigator.language

/**
 * Most countries around the world today use the 24-hour system. However, the
 * 12-hour format, including am and pm, is officially used in a number of
 * countries, including the United States, Canada (except Québec), Australia,
 * New Zealand, and the Philippines.
 *
 * https://en.wikipedia.org/wiki/12-hour_clock
 * https://en.wikipedia.org/wiki/Language_localisation
 */
export const has12HourClock = () => {
  return [
    //
    "en-AU",
    "en-CA",
    "en-GB",
    "en-IN",
    "en-NZ",
    "en-US",
  ].includes(browserLocale())
}

export function getBrowserClockType(): TUser["clock_type"] {
  return has12HourClock() ? "12" : "24"
}

const DATE_FORMAT_EN = "MMM d y hh:mm a"
const DATE_FORMAT_EN_SECONDS = "MMM d y hh:mm:ss a"
const DATE_FORMAT_WORLD = "d MMM y HH:mm"
const DATE_FORMAT_WORLD_SECONDS = "d MMM y HH:mm:ss"
const DATE_FORMAT_EN_NO_TIME = "MMM d y"
const DATE_FORMAT_WORLD_NO_TIME = "d MMM y"
const DATE_FORMAT_EN_NO_YEAR = "MMM d hh:mm a"
const DATE_FORMAT_EN_NO_YEAR_SECONDS = "MMM d hh:mm:ss a"
const DATE_FORMAT_WORLD_NO_YEAR = "d MMM HH:mm"
const DATE_FORMAT_WORLD_NO_YEAR_SECONDS = "d MMM HH:mm:ss"
const DATE_FORMAT_EN_NO_YEAR_NO_TIME = "MMM d"
const DATE_FORMAT_WORLD_NO_YEAR_NO_TIME = "d MMM"
const TIME_FORMAT_WORLD_SECONDS = "HH:mm:ss"
const TIME_FORMAT_EN_SECONDS = "hh:mm:ss a"

function getDateFormat({
  clockFormat,
  excludeTime,
  excludeYear,
  includeSeconds,
}: {
  clockFormat: number
  excludeTime: boolean
  excludeYear: boolean
  includeSeconds?: boolean
}) {
  if (clockFormat === 12 && excludeTime && excludeYear) {
    return DATE_FORMAT_EN_NO_YEAR_NO_TIME
  }

  if (clockFormat === 12 && excludeTime && !excludeYear) {
    return DATE_FORMAT_EN_NO_TIME
  }

  if (clockFormat === 12 && !excludeTime && excludeYear && !includeSeconds) {
    return DATE_FORMAT_EN_NO_YEAR
  }

  if (clockFormat === 12 && !excludeTime && excludeYear && includeSeconds) {
    return DATE_FORMAT_EN_NO_YEAR_SECONDS
  }

  if (clockFormat === 12 && !excludeTime && !excludeYear && !includeSeconds) {
    return DATE_FORMAT_EN
  }

  if (clockFormat === 12 && !excludeTime && !excludeYear && includeSeconds) {
    return DATE_FORMAT_EN_SECONDS
  }

  if (clockFormat !== 12 && excludeTime && excludeYear) {
    return DATE_FORMAT_WORLD_NO_YEAR_NO_TIME
  }

  if (clockFormat !== 12 && excludeTime && !excludeYear) {
    return DATE_FORMAT_WORLD_NO_TIME
  }

  if (clockFormat !== 12 && !excludeTime && excludeYear && !includeSeconds) {
    return DATE_FORMAT_WORLD_NO_YEAR
  }

  if (clockFormat !== 12 && !excludeTime && excludeYear && includeSeconds) {
    return DATE_FORMAT_WORLD_NO_YEAR_SECONDS
  }

  if (clockFormat !== 12 && !excludeTime && !excludeYear && !includeSeconds) {
    return DATE_FORMAT_WORLD
  }

  return DATE_FORMAT_WORLD_SECONDS
}

/**
 * Returns the time format for the given clock type, including seconds
 */
function getTimeFormat(clockFormat: number): string {
  if (clockFormat === 12) {
    return TIME_FORMAT_EN_SECONDS
  } else {
    return TIME_FORMAT_WORLD_SECONDS
  }
}

/** Return localized date string
 *
 * If a localized date format is not specified, we will fallback to displaying
 * time based on the user's preferred langauge.
 *
 * @param {string} date ISO-formatted date string
 * @param {TClockTypeMaybe} clockType Format time in 12- or 24-hour format
 * @param {string | undefined} timezone The timezone to which the date will be converted
 * @param {boolean | undefined} excludeTime Exclude timestamp from date
 * @param {boolean | undefined} excludeYear Exclude year from date
 */
export function formatDate({
  date,
  clockType,
  timezone,
  excludeTime,
  excludeYear,
  includeSeconds,
}: {
  date: string
  clockType?: TClockTypeMaybe
  timezone?: Optional<string>
  excludeTime?: boolean
  excludeYear?: boolean
  includeSeconds?: boolean
}) {
  try {
    const clockFormat = Number(clockType ?? getBrowserClockType())

    const dateFormat = getDateFormat({
      clockFormat,
      excludeTime: !!excludeTime,
      excludeYear: !!excludeYear,
      includeSeconds,
    })

    const isoTime = !!timezone
      ? utcToZonedTime(date, timezone) //(Works) Time and Date are converted correctly but timezone Locale remains unchanged
      : parseISO(date)
    return format(isoTime, dateFormat)
  } catch (err) {
    return date
  }
}

export function formatUtcDate({
  date,
  excludeTime,
}: {
  date: string
  excludeTime?: boolean
}) {
  return formatDate({
    date,
    timezone: "utc",
    clockType: "24",
    excludeTime,
    includeSeconds: true,
  })
}

/**
 * Formats a given iso date string to a localized time string, including seconds
 * @param {string} date ISO-formatted date string
 * @param {TClockTypeMaybe} clockType Format time in 12- or 24-hour format
 * @returns The time formated as a string, hh:mm:ss for 24h and hh:mm:ss a for 12h
 */
export function formatDateAsTime({
  date,
  clockType,
  timezone,
  excludeSeconds,
}: {
  date: string
  clockType: TClockTypeMaybe
  timezone?: string
  excludeSeconds?: boolean
}): string {
  try {
    const clockFormat = Number(clockType ?? getBrowserClockType())
    const timeFormat = getTimeFormat(clockFormat)
    const isoTime = !!timezone
      ? utcToZonedTime(date, timezone) //(Works) Time and Date are converted correctly but timezone Locale remains unchanged
      : parseISO(date)

    if (!!excludeSeconds) {
      const hour12 = clockType === "12"
      const localeTimeString = getLocaleTimeString({ date: isoTime, hour12 })

      return localeTimeString
    }
    return format(isoTime, timeFormat)
  } catch (err) {
    return date
  }
}

/** Format time according to locale
 *
 * If a localized date format is not specified, we will fallback to displaying
 * time based on the user's preferred langauge.
 *
 * @param {string} time 24-hour time, e.g. '23:08'
 * @param {TClockTypeMaybe} clockType Format time in 12- or 24-hour format
 */
export function formatTimeString(
  time: string,
  clockType: TClockTypeMaybe
): string {
  const clockFormat = Number(clockType)
  const clock24To12 = () =>
    new Date("1970-01-01T" + time + "Z").toLocaleTimeString(undefined, {
      timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    })
  switch (clockFormat) {
    case 12:
      return clock24To12()
    case 24:
      return time
    default:
      return has12HourClock() ? clock24To12() : time
  }
}

export const extractHHMM = (date: Date, clockType: TClockTypeMaybe) => {
  const _clockType = clockType ?? getBrowserClockType()
  const hour12 = _clockType === "12"
  const localeTimeString = getLocaleTimeString({ date, hour12 })

  return localeTimeString
}

function getLocaleTimeString({
  date,
  hour12,
}: {
  date: Date
  hour12: boolean
}) {
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12,
  })
}

export const getLocalTimeZone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone

export const getTimeZoneFromLoc = (
  lat: number | undefined,
  lng: number | undefined
) => {
  try {
    if (lat === undefined || lng === undefined) {
      return getLocalTimeZone()
    }
    return tzLookup(lat, lng)
  } catch (e) {
    return getLocalTimeZone()
  }
}

export function utcToHomeTimezone(
  date: string | Date,
  timezone: string | undefined
) {
  if (!!timezone) {
    return utcToZonedTime(date, timezone)
  }
  return new Date(date)
}

export function homeTimezoneToUTC(
  d: Date,
  currentTz: string | undefined
): Date {
  if (currentTz) return zonedTimeToUtc(d, currentTz)

  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return zonedTimeToUtc(d, browserTimezone)
}

/**
 * Function that takes a date object and formats it
 * to an ISO string in UTC without converting from the
 * local timezone.
 * @param date
 */
export function dateToUTCISOStringWithoutTzConversion(localDate: Date) {
  const utcDate = new Date(
    Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds(),
      localDate.getMilliseconds()
    )
  )
  return utcDate.toISOString()
}

export function capitalizeFirstChar(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
