import { components, paths } from "@minuthq/minut-api-types/schema"

import {
  IBasePaginatedEndpoint,
  IPagingFilter,
} from "src/data/pagination/types/paginationTypes"
import { Maybe } from "src/utils/tsUtil"

export type THomeGroup =
  paths["/organizations/{organization_id}/homegroups/{homegroup_id}"]["get"]["responses"]["200"]["content"]["application/json"]

export interface IHomeGroupMember {
  member_id: string
  user_id: string
  joined_at: string // format: date-time
  role: HomeGroupRole
  name: string
  email: string
}

export interface IFetchHomeGroupMembers extends IBasePaginatedEndpoint {
  members: IHomeGroupMember[]
}

export enum HomeGroupRole {
  OWNER = "owner",
  ADMIN = "admin",
  MEMBER = "member",
  RESPONDER = "responder",
}

export enum HomeGroupRank {
  RESPONDER,
  MEMBER,
  ADMIN,
}

export interface IFetchHomeGroups extends IBasePaginatedEndpoint {
  homegroups: THomeGroup[]
}

export interface IFetchHomeGroupHomes extends IBasePaginatedEndpoint {
  homes: IHomeGroupHome[]
}

export type TPostHomeGroup =
  paths["/organizations/{organization_id}/homegroups"]["post"]["requestBody"]["content"]["application/json"]

export interface IPostHomeGroupHome {
  home_id: string
}

export interface IHomeGroupHome {
  id: string
  name: string
}

export type TPatchHomeGroup =
  paths["/organizations/{organization_id}/homegroups/{homegroup_id}"]["patch"]["requestBody"]["content"]["application/json"]

export type THomeGroupFilter =
  paths["/organizations/{organization_id}/homegroups"]["get"]["parameters"]["query"]

export interface IHomeGroupHomesFilter extends IPagingFilter {}

export type TMaybeHomeGroup = Maybe<THomeGroup>

export type TPostImportHomeGroupHomesResponse =
  paths["/organizations/{organization_id}/homegroups/{homegroup_id}/csv/homes"]["post"]["responses"]["201"]["content"]["application/json"]

export type TPostImportHomeGroupHomes400Response =
  paths["/organizations/{organization_id}/homegroups/{homegroup_id}/csv/homes"]["post"]["responses"]["400"]["content"]["application/json"]

export type TPostImportHomeGroupHomes413Response =
  paths["/organizations/{organization_id}/homegroups/{homegroup_id}/csv/homes"]["post"]["responses"]["413"]["content"]["application/json"]

export type TPostImportHomeGroupHomes415Response =
  paths["/organizations/{organization_id}/homegroups/{homegroup_id}/csv/homes"]["post"]["responses"]["415"]["content"]["application/json"]

export type CSVRowError = components["schemas"]["CsvRowError"]
