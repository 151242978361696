import styled from "styled-components"

import { SearchFilter } from "src/components/Filter/SearchFilter"
import { ParadiseOrdersFilters } from "src/components/Paradise/ParadiseOrders/ParadiseOrdersFilter"
import { OrderStatusBadge } from "src/components/Paradise/ParadiseOrders/ParadiseOrderStatusBadge"
import { ParadiseOrderTrackingReference } from "src/components/Paradise/ParadiseOrders/ParadiseOrderTrackingReference"
import { TParadiseOrdersSearchFilter } from "src/components/Paradise/ParadiseOrders/useParadiseOrdersFilter"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { TopWrapper } from "src/components/Paradise/sharedStyles"
import { chargebeeInvoiceUrl } from "src/constants/hrefs"
import { countryCodeToCountry } from "src/data/countries/countryUtil"
import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { TParadiseUser } from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import {
  TSetSearchParamsProp,
  TSortedSearchParams,
} from "src/router/useSearchParams"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { ColumnPopoverWrapper } from "src/ui/GridTable/useTableColumns/tableColumnStyles"
import {
  TableColumn,
  TTableSort,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import FilterIcon from "src/ui/icons/calibrating.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { capitalizeFirstChar, formatUtcDate } from "src/utils/l10n"
import { Maybe } from "src/utils/tsUtil"

export function ParadiseOrdersTable({
  orders,
  users,
  filter,
  setFilter,
  sortedFilters,
  sort,
  setSort,
  isLoadingMore,
  isLoading,
  error,
  offset,
  limit,
  setOffset,
  totalCount,
  disableColumnHiding,
  tableStorageKey,
  hiddenColumns,
}: {
  orders: TParadiseOrder[]
  users?: TParadiseUser[]
  filter: TParadiseOrdersSearchFilter
  setFilter: TSetSearchParamsProp<TParadiseOrdersSearchFilter>
  sortedFilters: TSortedSearchParams<keyof TParadiseOrdersSearchFilter>
  sort?: Maybe<TTableSort>
  setSort?: (sort: TTableSort) => void
  isLoadingMore?: boolean
  isLoading?: boolean
  error?: {
    hasError: boolean
    title: React.ReactNode
    description?: React.ReactNode
  }
  offset: number
  limit: number
  setOffset: (offset: number) => void
  totalCount?: number
  disableColumnHiding?: boolean
  tableStorageKey?: string
  hiddenColumns?: (keyof TParadiseOrder)[]
}) {
  const { navigate } = useRouter()

  const tableColumns: TableColumn<TParadiseOrder, keyof TParadiseOrder>[] = [
    {
      label: "Order number",
      value: "order_number",
      columnWidth: "min-content",
      renderCell: (data) => (
        <div>
          <InternalLink
            to={Routes.ParadiseOrder.location(data.order_number).pathname}
          >
            {data.order_number}
          </InternalLink>
        </div>
      ),
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.orders_order_number ?? undefined}
            placeholder="Serach by order number"
            onChange={(v) => setFilter("orders_order_number", v)}
          />
          <MText variant="bodyS">Make sure to type in the exact ID</MText>
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      label: "Created at",
      value: "created_at",
      disabled: true,
      columnWidth: "min-content",
      renderCell: (data) => (
        <div>{formatUtcDate({ date: data.created_at })}</div>
      ),
      enableSort: true,
    },

    {
      label: "User",
      value: "user_id",
      disabled: true,
      columnWidth: "min-content",
      renderCell: (data) => {
        const user = users?.find((u) => u.id === data.user_id)

        return (
          <div>
            {data.user_id && (
              <InternalLink
                to={Routes.ParadiseUser.location(data.user_id).pathname}
              >
                {user?.email || data.user_id}
              </InternalLink>
            )}
          </div>
        )
      },
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.orders_user_id ?? undefined}
            placeholder="User ID"
            onChange={(v) => setFilter("orders_user_id", v)}
          />

          <Note>
            <MText variant="bodyS" color="tertiary">
              *Full id is required
            </MText>
          </Note>
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      label: "Total",
      value: "total_price",
      columnWidth: "min-content",
      renderCell: (data) => <div>{data.total_price.formatted_amount}</div>,
    },
    {
      label: "Status",
      value: "status",
      columnWidth: "min-content",
      renderCell: (data) => (
        <Block>
          <OrderStatusBadge status={data.status} />
        </Block>
      ),
      enableSort: true,
    },
    {
      label: "Items",
      value: "line_items",
      disabled: true,
      columnWidth: "min-content",
      renderCell: (data) => {
        const itemsCount = data.line_items.length
        return itemsCount === 1 ? (
          <div>{itemsCount} item</div>
        ) : (
          <div>{itemsCount} items</div>
        )
      },
    },
    {
      label: "Shipping country",
      value: "shipping_address",
      disabled: true,
      columnWidth: "min-content",
      renderCell: (data) => (
        <div>{countryCodeToCountry(data.shipping_address.country)}</div>
      ),
    },
    {
      label: "Tracking",
      value: "tracking_reference",
      disabled: true,
      columnWidth: "min-content",
      renderCell: (data) =>
        data.tracking_reference ? (
          <ParadiseOrderTrackingReference
            trackingReference={data.tracking_reference}
            trackingUrl={data.tracking_url}
          />
        ) : (
          <div></div>
        ),
    },
    {
      label: "Invoice",
      value: "invoice_id",
      disabled: true,
      columnWidth: "min-content",
      renderCell: (data) =>
        data.invoice_id && (
          <div>
            <ExternalLink href={chargebeeInvoiceUrl(data.invoice_id)}>
              {data.invoice_id}
            </ExternalLink>
          </div>
        ),
    },
    {
      label: "Origin",
      value: "origin",
      disabled: true,
      columnWidth: "min-content",
      renderCell: (data) => <div>{capitalizeFirstChar(data.origin.name)}</div>,
    },
  ]

  const {
    headerElements,
    interactiveColumns,
    interactiveVisibleColumns,
    rows,
    updateColumnVisibility,
    templateColumns,
  } = useTableColumns<TParadiseOrder>({
    columns: tableColumns.map((c) => ({
      ...c,
      hidden: hiddenColumns?.includes(c.value),
    })),
    data: orders,
    sort,
    onSortChange: setSort,
    options: {
      localStorageKey: tableStorageKey,
      disableColumnHiding,
    },
  })

  return (
    <div>
      <TopWrapper>
        <ParadiseOrdersFilters
          setFilter={setFilter}
          sortedFilters={sortedFilters}
        />

        {!disableColumnHiding && (
          <DropdownMultiSelect
            label="Columns"
            selectedValues={interactiveVisibleColumns.map((c) => c.value)}
            options={interactiveColumns}
            onChange={({ checked, option }) => {
              updateColumnVisibility(option.value, !checked)
            }}
          />
        )}
      </TopWrapper>
      <ParadiseTable
        rows={rows ?? []}
        templateColumns={templateColumns}
        header={headerElements}
        onRowClick={(index) => {
          const order = orders[index]
          if (order) {
            navigate(Routes.ParadiseOrder.location(order.order_number))
          }
        }}
        error={error}
        isLoadingMore={isLoadingMore}
        isLoading={isLoading}
      />

      <ParadisePager
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        totalCount={totalCount}
      />
    </div>
  )
}

const Note = styled.div`
  margin-top: ${spacing.XS};
  margin-left: ${spacing.M};
`

const Block = styled.div`
  display: block;
`
